import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import img from '../images/Animation - 1709313300851.json'
import image3 from "../images/carousel_3.jpeg";
import Lottie from "lottie-react";
import { useMediaQuery } from "react-responsive";
import toast from "react-hot-toast";
import axios from "axios";
import Slider from "react-slick";
let backendUrl = process.env.REACT_APP_BACKEND;

export default function Cart() {

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const isMobile = useMediaQuery({ maxWidth: 844 });

  const [cart, setCart] = useState([]),
    [, setTotalQuantity] = useState(0),
    [error, setError] = useState("");
  const guestID = localStorage.getItem("guestID");

  let getCartItems = () => {
    fetch(`${backendUrl}/api/cart`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => res.json())
      .then(data => {
        setCart(data);
      })
      .catch(error => {
        console.error('Error fetching cart items:', error);
        // Handle error if needed
      });
  };

  // Fetch cart items when component mounts
  // useEffect(() => {
  //   getCartItems();
  // }, []);

  let removeSingleProduct = product => {
    fetch(`${backendUrl}/api/cart/delete/${product}`, { method: "DELETE", credentials: 'include', })
      .then(res => res.json())
      .then(data => console.log(data));
    window.location.reload();
  };

  let removeAllProducts = () => {
    fetch(`${backendUrl}/api/cart/delete`, { method: "DELETE", credentials: 'include', })
      .then(res => res.json())
      .then(data => console.log(data));
    window.location.reload();
  };

  // let increaseQuantity = async product => {
  //   let response;
  //   try {
  //     response = await fetch(`${backendUrl}/api/cart/${product}/increase/${guestID}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //       credentials: 'include',
  //       body: JSON.stringify({}),
  //     });
  //     response.json().then(result => setError(result));
  //     toast.success("Quantity Successfully Changed");
  //     getCartItems();
  //   } catch (error) {
  //     toast.error("Error Occured");
  //   }
  // };

  function increaseQuantity(product) {
    axios.post(`${backendUrl}/api/cart/${product}/increase/${guestID}`)
      .then((res) => {
        console.log(res);
        toast.success(res.data);
        getCartItems();
      }).catch((err) => {
        console.log(err);
        toast.error(err.response.data);
      })
  }


  let decreaseQuantity = async product => {
    let response = await fetch(`${backendUrl}/api/${product}/decrease/${guestID}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      credentials: 'include',
      body: JSON.stringify({}),
    });
    setError("");
    getCartItems();
    toast.success("Quantity Successfully Changed");
    return response.json();
  };

  let getTotal = () => {
    console.log("Cart:", cart);
    return cart.reduce((total, item) => {
      const effectivePrice = item.sale ? item.price - item.sale : item.price;
      console.log(`Item: ${item.code}, Quantity: ${item.quantity}, Price: ${effectivePrice}`);
      return total + effectivePrice * item.quantity;
    }, 0);
  };



  let calculateTotalQuantity = useCallback(() => {
    const total = cart.reduce((accumulator, item) => {
      return accumulator + item.quantity;
    }, 0);
    setTotalQuantity(total);
  }, [cart]);

  useEffect(() => {
    getCartItems();
    calculateTotalQuantity();
  }, []);

  // console.log(cart);

  return (
    <div className="cart">
      <Container>
        {cart.length === 0 ? (<div className='d-flex flex-column align-items-center'>
          <div className="col-md-7 col-12">
            <div className='text-center'>
              <Lottie animationData={img}></Lottie>
              <p className="text-white fs-3">Your Cart is empty !</p>
            </div>
          </div>
        </div>
        ) : (
          <div className="cart_items">
            <h2>Shopping Cart</h2>

            {isMobile ? null : <div className="row text-center text-white mb-3">
              <div className="col-md-4">
                <h4>Image</h4>
              </div>
              <div className="col-md-4">
                <h4>Quantity</h4>
              </div>
              <div className="col-md-4">
                <h4>Price</h4>
              </div>
            </div>}
            {cart.map((item, idx) => <div className="row" key={idx}>
              <div className="col-md-4 text-center text-white">
                <div className="row">
                  {isMobile ? <div className="col-md-6 p-5">
                    {/* <img src={Boolean(item.images) ? item.images : image3} className="w-100" /> */}
                    {item.images.length > 1 ?
                      <Slider {...settings}>
                        {item.images.map((image, idx) => (
                          <div>
                            <img key={idx} src={image} alt="item img" className="w-100" />
                          </div>
                        ))}
                      </Slider>
                      :
                      <img src={item.images[0]} alt="item img" className="w-100" />
                    }
                  </div> : <div className="col-md-6">
                    {/* <img src={Boolean(item.images) ? item.images : image3} className="w-100" /> */}
                    {item.images.length > 1 ?
                      <Slider {...settings}>
                        {item.images.map((image, idx) => (
                          <div>
                            <img key={idx} src={image} alt="item img" className="w-100" />
                          </div>
                        ))}
                      </Slider>
                      :
                      <img src={item.images[0]} alt="item img" className="w-100" />
                    }
                  </div>}

                  {isMobile ? <div className="col-md-6">
                    <h3 className="mt-2">{item.code}</h3>
                    <h3>Size : {item.size.toUpperCase()}</h3>
                    <button className="btn btn-danger w-25 fs-4">Remove</button>
                  </div> : <div className="col-md-6">
                    <h5>{item.code}</h5>
                    <h5>Size : {item.size.toUpperCase()}</h5>
                    <button className="btn btn-danger" onClick={() => removeSingleProduct(item.code)}>Remove</button>
                  </div>}

                </div>
              </div>
              {isMobile ? <div className="col-md-4 text-center text-white fs-3">
                <div className="mt-4">
                  <i class="fa-solid fa-chevron-up me-2" onClick={() => increaseQuantity(item.code)}></i>
                  {item.quantity}
                  <i class="fa-solid fa-chevron-down ms-2" onClick={() => decreaseQuantity(item.code)}></i>
                </div>
              </div> : <div className="col-md-4 text-center text-white">
                <div className="mt-4">
                  <i class="fa-solid fa-chevron-up me-2" onClick={() => increaseQuantity(item.code)}></i>
                  {item.quantity}
                  <i class="fa-solid fa-chevron-down ms-2" onClick={() => decreaseQuantity(item.code)}></i>
                </div>
              </div>}
              {isMobile ? <div className="col-md-4 text-center text-white fs-3">
                <div className="mt-4">
                  {item.sale ? item.price - item.sale : item.price} EGP
                </div>
              </div> : <div className="col-md-4 text-center text-white">
                <div className="mt-4">
                  {item.sale ? item.price - item.sale : item.price} EGP
                </div>
              </div>}
              {isMobile ? <hr className="text-white mt-3 fw-bold w-75 m-auto mb-5" /> : <hr className="text-white mt-3 fw-bold" />}
            </div>)}
            {isMobile ? <><div className="sub_total mt-4 text-center">
              subtotal<span>{getTotal()} EGP</span>
            </div><div className="tax text-center mt-4 fs-6 mb-2">Tax included and shipping calculated at checkout</div><div className="mt-4 text-center">
                <Link to="/checkout" className="me-4">
                  <button className="btn btn-primary fs-3">Proceed To Checkout</button>
                </Link>
                <button onClick={removeAllProducts} className="btn btn-danger fs-3">Clear Cart</button>
              </div></> : <><div className="sub_total mt-4 text-end">
                subtotal<span>{getTotal()} EGP</span>
              </div><div className="tax text-end mt-4 fs-6">Tax included and shipping calculated at checkout</div><div className="text-end mt-4">
                <Link to="/checkout" className="me-4">
                  <button className="btn btn-primary fs-5">Proceed To Checkout</button>
                </Link>
                <button onClick={removeAllProducts} className="btn btn-danger fs-5">Clear Cart</button>
              </div></>}
          </div>
        )}
      </Container >
    </div >
  );
}
