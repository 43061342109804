import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Container } from "react-bootstrap";
let backendUrl = process.env.REACT_APP_BACKEND;

export default function FooterItemPage() {
  const { footerItem: label } = useParams();

  useEffect(
    function () {
      async function getFooterContent() {
        fetch(`${backendUrl}/api/footerItems/${label}`)
          .then(res => res.json())
          .then(data => {
            const contentContainer = document.getElementById(label);
            if (contentContainer) contentContainer.innerHTML = data.content;
          });
      }

      getFooterContent();
    },
    [label]
  );

  return (
    <div className="policy text-center text-white">
      <Container>
        <h2>{label}</h2>
        <div className="content" id={label}></div>
      </Container>
    </div>
  );
}
