import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logo-removebg-preview.png";
import style from './nav.module.css'
let backendUrl = process.env.REACT_APP_BACKEND;

export default function Navigation() {
  const [totalQuantity, setTotalQuantity] = useState(0),
    [, setCart] = useState([]),
    [boxes, setBoxes] = useState([]);

  // let getCartItems = () => {
  //   fetch(`http://localhost:3001/api/cart`)
  //     .then(res => res.json())
  //     .then(data => {
  //       setCart(data);
  //       setTotalQuantity(data.length);
  //     });
  // };
  let getCartItems = () => {
    fetch(`${backendUrl}/api/cart`, {
      method: 'GET',
      credentials: 'include', // Include credentials (cookies) in the request
    })
      .then(res => res.json())
      .then(data => {
        setCart(data); // Assuming data is an array of cart items
        setTotalQuantity(data.length);
      })
      .catch(error => {
        console.error('Error fetching cart items:', error);
        // Handle error if needed
      });
  };

  let getBoxes = () => {
    fetch(`${backendUrl}/api/homeBoxes`)
      .then(res => res.json())
      .then(data => setBoxes(data));
  };

  useEffect(() => {
    getCartItems();
    getBoxes();
  }, []);

  return (
    <nav className={style.navBack + " navbar navbar-expand-lg"}>
      <div className="container">
        <div className="d-flex">
          <Link className="navbar-brand" to={'/'}>
            <img src={logo} alt="logo" className={style.navLogo} />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <button className="btn bg-white"><i class="fa-solid fa-bars fs-1"></i></button>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link active" to={'/'}>
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-decoration-none text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Categories
              </a>
              <ul className="dropdown-menu">
                {boxes.map(box => (
                  <li key={box.title}><a className="dropdown-item" href={`/products/${box.title}`}>{box.title}</a></li>
                ))}
              </ul>
            </li>
            <Link to="/contact-us">Make A Review</Link>
            <Link to="/myOrders">My Orders</Link>
            <Link className="nav-link active" to={'/cart'}>
              <div className="position-relative">
                <FontAwesomeIcon className="fs-5 " icon={faCartShopping} />
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {totalQuantity}
                </span>
              </div>
            </Link>
          </ul>
        </div>
      </div>
    </nav>

  );
}
