import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import HomeCarousel from "../components/HomeCarousel";
import HomeBox from "../components/HomeBox";
import Slider from "../components/Slider";
import './style.css'; // Import your CSS file

let backendUrl = process.env.REACT_APP_BACKEND;

export default function Home() {
  const [stock, setStock] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [boxes, setBoxes] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLaptop = useMediaQuery({ minWidth: 768, maxWidth: 1200 });

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const createSession = () => {
    fetch(`${backendUrl}/initialize-session`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => res.json())
      .then(data => {
        console.log("Session", data);
        localStorage.setItem('guestID', data.guestID);
      })
      .catch(error => {
        console.error('Error initializing session:', error);
      });
  };

  useEffect(() => {
    const getData = () => {
      fetch(`${backendUrl}/api/sliders`)
        .then(res => res.json())
        .then(data => {
          console.log("sliders", data);
          setSliders(data)
        });

      fetch(`${backendUrl}/api/homeBoxes`)
        .then(res => res.json())
        .then(data => {
          // console.log("boxes", data);
          setBoxes(data);
        });

      fetch(`${backendUrl}/api/products`)
        .then(res => res.json())
        .then(data => {
          setStock(data)
        });
    };

    createSession();
    getData();
  }, []);

  const renderBoxes = () => {
    if (isMobile || isLaptop) {
      const mobileBoxes = [];
      let rowIndex = 0;

      // Check if the number of boxes is odd
      const isOdd = boxes.length % 2 !== 0;

      while (rowIndex < boxes.length) {
        if (rowIndex === 0 && isOdd) {
          // 2 items in the first row if the number of boxes is odd
          mobileBoxes.push(boxes.slice(rowIndex, rowIndex + 2));
          rowIndex += 2;
        } else {
          // 2 item row
          if (rowIndex + 2 <= boxes.length) {
            mobileBoxes.push(boxes.slice(rowIndex, rowIndex + 2));
            rowIndex += 2;
          } else {
            // 1 item row if only one box is left
            mobileBoxes.push([boxes[rowIndex]]);
            rowIndex += 1;
          }
        }
      }

      return mobileBoxes.map((boxRow, index) => (
        <Row key={index} className="mb-4 w-100">
          {boxRow.map((box, boxIndex) => (
            <Col key={box.title}>
              <HomeBox title={box.title} image={box.image} stock={box.stock} />
            </Col>
          ))}
        </Row>
      ));
    } else {
      // Default chunking for larger screens (3 items per row)
      return chunkArray(boxes, 2).map((boxRow, rowIndex) => (
        <Row key={rowIndex} className="mb-4 w-100">
          {boxRow.map(box => (
            <Col key={box.title} className="mb-4">
              <HomeBox title={box.title} image={box.image} stock={box.stock} />
            </Col>
          ))}
        </Row>
      ));
    }
  };

  return (
    <div className="home_page">
      <HomeCarousel />
      <Container>
        <Container className="new_collection">
          <div className="new_collection_container">
            {renderBoxes()}
          </div>
        </Container>
        {sliders.map((slider, idx) => (
          slider.boxNames && slider.boxNames.length > 0
            ? null
            : <Slider displayHeader key={slider.title} title={slider.title} stock={slider.stock} />
        ))}
        {/* <Slider displayHeader title="Offers" stock={stock.filter(product => Boolean(product.sale))} />
        <Slider displayHeader title="New Collection" stock={stock.filter(product => Boolean(product.newCollection))} /> */}
      </Container>
    </div>
  );
}
