import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container } from "react-bootstrap";

import ProductGroup from "../components/ProductGroup";
let backendUrl = process.env.REACT_APP_BACKEND;

export default function Products() {
  const params = useParams();

  const [stock, setStock] = useState([]);
  const [leagues, setLeagues] = useState([]);


  useEffect(() => {
    const getData = () => {
      fetch(`${backendUrl}/api/getProductsByTitle/${params.category}`)
        .then(res => res.json())
        .then(data => setStock(data[0].stock));
    };

    getData();
  }, [params]);

  return (
    <div className="products">
      <Container>
        <div className="previous_seasons">
          <ProductGroup title={params.category} showSizeFilter={false} stock={stock} />
        </div>
      </Container>
    </div>
  );
}
