import React, { useState, useEffect } from "react";
import SliderComponent from "./Slider";
import Slider from "react-slick";
import { Link, NavLink } from "react-router-dom";
import image2 from '../images/WhatsApp Image 2023-12-25 at 14.30.29 (3).jpeg'
import Product from "./Product";
let backendUrl = process.env.REACT_APP_BACKEND;

export default function ProductGroup({
  title = "",
  stock = [
    {
      _id: "",
      sizes: [{ size: "", quantity: 0 }],
      code: "",
      model: "",
      kit: "",
      collectionName: "",
      type: "",
      price: 0,
      description: "",
      sale: 0,
      newCollection: "",
      league: "",
      brand: "",
      images: "",
      __v: 0,
    },
  ],
}) {
  localStorage.setItem("title", title);
  const [boxSilders, setBoxSilders] = useState(stock);
  const [boxType, setBoxType] = useState("");
  const [boxAllProducts, setBoxAllProducts] = useState(stock);

  function getBoxProducts() {
    fetch(`${backendUrl}/api/getProductsByTitle/${title}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data[0].boxType === "products") {
          setBoxAllProducts(data[0].stockForProducts);
        }
        setBoxSilders(data[0].stock);
        setBoxType(data[0].boxType);
      });
  }

  const calculateSalePrice = (product) => {
    if (typeof product.price !== "number" || typeof product.sale !== "number") {
      return "Invalid input";
    }
    // const salePrice = product.price - (product.price * product.sale) / 100;
    const salePrice = product.price - product.sale;
    return salePrice.toFixed(2);
  };

  useEffect(() => {
    getBoxProducts();
  }, []);

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <h2>{title}</h2>
      {boxType === "products" ? (
        <div className="container">
          <div className="row">
            {boxAllProducts.map((product, idx) => (
              <div key={idx} className="col-md-4 col-6">
                <NavLink
                  to={`/products/${title}/${product.code}`}
                  key={product._id}
                >
                  <div className="bg-gradient p-5 rounded-2 mb-3">
                    <div className="image">
                      {product.images.length > 1 ?
                        <Slider {...settings}>
                          {product.images.map((image, idx) => (
                            <div>
                              <img key={idx} src={image} alt="product img" className="w-100" />
                            </div>
                          ))}
                        </Slider>
                        :
                        <img src={product.images[0]} alt="product img" className="w-100" />
                      }
                    </div>
                    <div className="info">
                      <div className="fw-bold mt-3 fs-4 text-white">{product.code}</div>
                      <div className="text-white text-center">{product.description}</div>
                      <div className="d-flex gap-4 w-100 justify-content-center size text-uppercase text-center mb-2 fs-5">
                        {product.sizes.every((s) => s.quantity === 0) ? (
                          <span className="fw-bold mt-2 fs-5 text-danger">Out of stock</span>
                        ) : (
                          product.sizes.map((s) =>
                            s.quantity === 0 ? null : <span key={s.size} className="me-3 fs-5 text-white">{s.size}</span>
                          )
                        )}
                      </div>
                      {!product.sizes.every((s) => s.quantity === 0) && (
                        <div className="inner_info d-flex justify-content-between">
                          {calculateSalePrice(product) === "Invalid input" ? (
                            <div className="mt-3 fw-bold fs-5 text-white">{product.price} EGP</div>
                          ) : (
                            <div className="mt-3 fw-bold fs-5 text-danger text-decoration-line-through">{product.price} EGP</div>
                          )}
                          {calculateSalePrice(product) !== "Invalid input" ? (
                            <span className="mt-3 fw-bold fs-5 text-white">{calculateSalePrice(product)} EGP</span>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      ) : (
        boxSilders.map((slider) => (
          <div key={slider._id}>
            <h2 className="mt-4">{slider._doc.title}</h2>
            <div className="all_products">
              <Link to={`/products/${slider._doc.title}/all`} className="all_products">
                See All
              </Link>
            </div>
            <SliderComponent displayHeader={false} stock={slider.stock} title={title} />
          </div>
        ))
      )}
    </>
  );
}
