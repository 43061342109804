import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { NavLink } from "react-router-dom";
let backendUrl = process.env.REACT_APP_BACKEND;

export default function HomeCarousel() {
  const scrollToCategories = () => {
    const divToScrollTo = document.getElementsByClassName("container")[2];
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [images, setImages] = useState([]);

  const getData = () => {
    fetch(`${backendUrl}/api/carousel`)
      .then(res => res.json())
      .then(data => setImages(data));
  };
  useEffect(getData, []);

  return (
    <Carousel>

      {images.map(image => (
        <Carousel.Item key={image.id}>
            <img className="w-100" src={image.image} alt="Carousel" />
          <Carousel.Caption>
            <NavLink onClick={scrollToCategories}>Shop Now</NavLink>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}