import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import image2 from "../images/carousel_2.jpeg";
import Slider from "react-slick";
import './ProductDetails.css'

let backendUrl = process.env.REACT_APP_BACKEND;

export default function ProductDetails() {

  let params = useParams();
  let nav = useNavigate();
  const guestID = localStorage.getItem("guestID");


  const [selectedSize, setSelectedSize] = useState(),
    [product, setProduct] = useState();
  const [sizes, setSizes] = useState(["xs", "s", "m", "l", "xl", "xxl", "xxxl", "xxxxl"]);

  let handleActive = element => {
    setSelectedSize(element);
  };


  const addToCart = async () => {
    if (!selectedSize) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select a size first",
      });
      return;
    }

    let timerInterval;
    Swal.fire({
      title: "Product Added To Cart",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        setTimeout(function () {
          nav("/cart");
          window.location.reload();
        }, 500);
      }
    });

    let response = await fetch(`${backendUrl}/api/cart/add/${params.code}/${guestID}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        selectedSize,
      }),
    });
    return response.json();
  };

  useEffect(() => {
    const getProduct = () => {
      fetch(`${backendUrl}/api/products/code/${params.code}`)
        .then((res) => res.json())
        .then((data) => {
          setProduct(data);
          console.log(data);

          if (data.sizes?.length > 0) {
            const availableSizes = data.sizes.filter((s) => s.quantity > 0).map((s) => s.size);
            setSizes(availableSizes);
          }
        });
    };
    getProduct();
  }, [params]);

  const settings = {
    customPaging: function (i) {
      return (
        <img src={product.images[i]} alt={`product thumbnail ${i}`} />
      );
    },
    dots: true,
    dotsClass: "slic-dots slick-thum",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const isOutOfStock = product?.sizes.every(s => s.quantity === 0);

  return (
    <div className="product_details mt-5">
      <Container>
        {product !== undefined ? (
          <div className="product_container">
            <div className="image text-center p-4">
              {/* <img src={product.images} alt="Product" /> */}
              {product.images.length > 1 ?
                <Slider {...settings}>
                  {product.images.map((image, idx) => (
                    <div>
                      <img key={idx} src={image} alt="product img" className="w-100" />
                    </div>
                  ))}
                </Slider>
                :
                <img src={product.images[0]} alt="product img" className="w-100" />
              }
            </div>
            <div className="infos text-center">
              <h3 className="title mt-4">{product.code}</h3>
              <h4 className="text-white mb-4">{product.description ? product.description : ""}</h4>
              {product.sale ? <div className="row mb-5">
                <div className="text-decoration-line-through text-danger fs-5">{product.price}EGP</div>
                <div className="text-white fs-3">{product.price - product.sale}EGP</div>
              </div> : <div className="price">{product.price}EGP</div>}
              {!isOutOfStock && <h3 className="text-white mb-3">Sizes</h3>}
              <div className="sizes w-100">
                {isOutOfStock ? (
                  <h3 className="text-danger mb-3">Out Of Stock</h3>
                ) : (
                  sizes.map((size) => (
                    <button
                      key={size}
                      className={selectedSize === size ? "active me-2 mt-3" : "me-2 mt-3"}
                      onClick={() => handleActive(size)}
                    >
                      {size.toUpperCase()}
                    </button>
                  ))
                )}
              </div>
              {isOutOfStock ? <button disabled onClick={addToCart} className="add_to_cart">
                Can't Add to Cart
              </button> : <button onClick={addToCart} className="add_to_cart">
                Add to cart
              </button>}
            </div>
          </div>
        ) : null}
      </Container>
    </div>
  );
}
