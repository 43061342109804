import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import image1 from "../images/carousel_3.jpeg";
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
import axios from "axios";
import Slider from "react-slick";
let backendUrl = process.env.REACT_APP_BACKEND;

export default function ProductsAll() {
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  let params = useParams();
  const [stock, setStock] = useState([]);

  const title = params.category;

  function getSliderProducts() {
    axios.get(`${backendUrl}/api/sliderProducts/${title}`)
      .then((res) => {
        console.log(res)
        setStock(res.data.stock)
      }).catch((err) => {
        console.log(err)
      })
  }

  const calculateSalePrice = product => {
    if (typeof product.price !== "number" || typeof product.sale !== "number") {
      return "Invalid input";
    }
    // const salePrice = product.price - (product.price * product.sale) / 100;
    const salePrice = product.price - product.sale;
    return salePrice.toFixed(2);
  };

  useEffect(() => {
    getSliderProducts();
  }, []);

  const isOutOfStock = (product) => {
    return product?.sizes?.every((s) => s.quantity === 0);
  };

  return (
    <div className="products_all">
      <Container>
        {
          <div>
            <h2 className="">{params.category}</h2>
            <div className="container">
              <div className="row">
                {stock.map((product, idx) => (
                  <div className="col-md-4 col-6">
                    <NavLink to={`/products/${params.category}/${product.code}`} key={idx} className="item">
                      <div className="productCard p-5 rounded bg-gradient mb-3">
                        {product.images.length > 1 ?
                          <Slider {...settings}>
                            {product.images.map((image, idx) => (
                              <div>
                                <img key={idx} src={image} alt="product img" className="w-100" />
                              </div>
                            ))}
                          </Slider>
                          :
                          <img src={product.images[0]} alt="product img" className="w-100" />
                        }

                        <div className="row text-white text-center mt-3">
                          <span className="fw-bold mt-3 fs-4">{product.code}</span>
                          <span className="">{product.description}</span>
                          <div className="d-flex justify-content-center">
                            {isOutOfStock(product) ? (
                              <span className="fw-bold mt-2 fs-5 text-danger">Out Of Stock</span>
                            ) : (
                              product.sizes?.map((s, index) => (
                                s.quantity > 0 ? <span key={index}><span className="me-3 fs-5 text-uppercase">{s.size}</span></span> : null

                              ))
                            )}
                          </div>
                          {isOutOfStock(product) ? null : <div>
                            {calculateSalePrice(product) === "Invalid input" ? (
                              <span className="mt-3 fw-bold fs-5">{product.price} EGP</span>
                            ) : (
                              <div className="d-flex justify-content-between">
                                <span className="mt-3 fw-bold fs-5 text-danger text-decoration-line-through">{product.price} EGP</span>
                                <span className="mt-3 fw-bold fs-5">{calculateSalePrice(product)}EGP</span>
                              </div>
                            )}
                          </div>}
                        </div>
                      </div>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      </Container>
    </div>
  );
}
