import React from "react";
import { Link } from "react-router-dom";
import image2 from "../images/temp-2.jpeg";


export default function Slider({ title = String(), image = String() }) {
  return (
    <div className="item w-100">
      <Link to={`/products/${title}`}>
        <img src={image} alt="Box" />
        <div className="category">{title}</div>
      </Link>
    </div>
  );
}
