
import React, { useState, useEffect } from "react";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
let backendUrl = process.env.REACT_APP_BACKEND;

export default function Footer() {
  const [footerItems, setFooterItems] = useState([]);

  useEffect(() => {
    const getData = () => {
      fetch(`${backendUrl}/api/footerItems`)
        .then(res => res.json())
        .then(data => setFooterItems(data));
    };
    getData();
  }, []);

  return (
    <footer className="text-center">
      <Container>
        <h2>Enjoy The Adventure</h2>
        <div className="policies_socials">
          <div className="moto">
            <h3>Soccer Corner Sports</h3>
            <p>
              Our goal is the bring to your a very well developed and quality product in the field of football and sport. By reviving the spirit of
              collaboration and sportiveness
            </p>
          </div>
          <ul className="policies text-capitalize">
            {footerItems.map(item => (
              <li key={item.label || Math.random() * 1000}>
                <NavLink to={item.label}>{item.label}</NavLink>
              </li>
            ))}
          </ul>
          <ul className="socials">
            <li>
              <NavLink target="_blank" to="https://www.facebook.com/profile.php?id=100076016882186&mibextid=9R9pXO">
                <FontAwesomeIcon icon={faFacebook} />
              </NavLink>
            </li>
            <li>
              <NavLink target="_blank" to="https://www.instagram.com/soccer.corner.sports?igsh=dW1mNzJnZ2piemps&utm_source=qr">
                <FontAwesomeIcon icon={faInstagram} />
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="copyright mb-3">© 2023 Soccer Corner Sports. All rights reserved</div>
      </Container>
    </footer>
  );
}
