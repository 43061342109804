import React, { useState } from "react";
import { Route, Routes } from "react-router";
import Navigation from "./components/Navigation";
import Home from "./views/Home";
// import AdminView from "./views/admin/AdminView";
// import ReviewsAdmin from "./views/admin/ReviewsAdmin";
// import AddProducts from "./views/admin/AddProducts";
// import AddFooterItem from "./views/admin/AddFooterItem";
// import DeleteFooterItem from "./views/admin/DeleteFooterItem";
// import AddSlider from "./views/admin/AddSlider";
// import DeleteSlider from "./views/admin/DeleteSlider";
// import AddHomeBox from "./views/admin/AddHomeBox";
// import DeleteHomeBox from "./views/admin/DeleteHomeBox";
// import AddCarouselImage from "./views/admin/AddCarouselImage";
// import DeleteCarouselImage from "./views/admin/DeleteCarouselImage";
// import Stock from "./views/admin/Stock";
// import EditProduct from "./views/admin/EditProduct";
// import ComplainsAdmin from "./views/admin/ComplainsAdmin";
// import NewOrders from "./views/admin/NewOrders";
// import New from "./views/admin/New";
// import Archived from "./views/admin/Archived";
import Footer from "./components/Footer";
import Products from "./views/Products";
import ProductDetails from "./views/ProductDetails";
import ContactUs from "./views/ContactUs";
import Cart from "./views/Cart";
import ProductsAll from "./views/ProductsAll";
import Checkout from "./views/Checkout";
import Shipping from "./views/policies/Shipping";
import Refund from "./views/policies/Refund";
// import Signup from "./components/Signup";
// import Signin from "./components/Signin";
import FooterItemPage from "./views/FooterItemPage";

import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import MyOrders from "./views/MyOrders";

function App() {
  const [appearFooter, setAppearFooter] = useState(true);
  // const [appearLoginSignup, setAppearLoginSignup] = useState(false);
  // const [loggedIn, setLoggedIn] = useState(false);

  return (
    <>
      <Navigation />

      <ScrollToTop />
      <Routes>
        {/* <Route path="/signup" element={<Signup setAppearLoginSignup={setAppearLoginSignup} setLoggedIn={setLoggedIn} />} />
        <Route path="/login" element={<Signin setAppearLoginSignup={setAppearLoginSignup} setLoggedIn={setLoggedIn} />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/myOrders" element={<MyOrders />} />
        <Route path="/checkout" element={<Checkout setAppearFooter={setAppearFooter} />} />
        <Route path="/products/:category" element={<Products />} />
        <Route path="/products/:category/all" element={<ProductsAll />} />
        <Route path="/products/:category/:code" element={<ProductDetails />} />
        {/* <Route path="/admin" element={<AdminView loggedIn={loggedIn} />} />
        <Route path="/admin/reviews" element={<ReviewsAdmin />} />
        <Route path="/admin/products/add" element={<AddProducts />} />
        <Route path="/admin/sliders/add" element={<AddSlider />} />
        <Route path="/admin/sliders/delete" element={<DeleteSlider />} />
        <Route path="/admin/addHomeBox" element={<AddHomeBox />} />
        <Route path="/admin/deleteHomeBox" element={<DeleteHomeBox />} />
        <Route path="/admin/addCarouselImage" element={<AddCarouselImage />} />
        <Route path="/admin/deleteCarouselImage" element={<DeleteCarouselImage />} />
        <Route path="/admin/addFooterItem" element={<AddFooterItem />} />
        <Route path="/admin/deleteFooterItem" element={<DeleteFooterItem />} />
        <Route path="/admin/stock" element={<Stock />} />
        <Route path="/admin/complains" element={<ComplainsAdmin />} />
        <Route path="/admin/newOrders" element={<NewOrders />} />
        <Route path="/admin/newOrders/new" element={<New />} />
        <Route path="/admin/newOrders/archived" element={<Archived />} />
        <Route path="/admin/edit-product/:code" element={<EditProduct />} /> */}
        <Route path="/shipping-policy" element={<Shipping />} />
        <Route path="/refund-exchange-policy" element={<Refund />} />
        <Route path="/:footerItem" element={<FooterItemPage />} />
      </Routes>
      {appearFooter && <Footer />}
    </>
  );
}

export default App;
