import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import image2 from "../images/temp-2.jpeg";
import Slider from "react-slick";
import "./Product.css";

export default function Product({

  title = String(),
  product = {
    _id: String(),
    sizes: [{ size: String(), quantity: Number() }],
    code: String(),
    model: String(),
    kit: String(),
    collectionName: String(),
    type: String(),
    price: Number(),
    description: String(),
    sale: Number(),
    newCollection: String(),
    league: String(),
    brand: String(),
    images: [],
    __v: Number(),
  },
}) {
  const calculateSalePrice = product => {
    if (typeof product.price !== "number" || typeof product.sale !== "number") {
      return "Invalid input";
    }
    // const salePrice = product.price - (product.price * product.sale) / 100;
    const salePrice = product.price - product.sale;
    return salePrice.toFixed(2);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };


  // console.log(product);

  // const isOutOfStock = product?.sizes.every(s => s.quantity == 0);
  const isOutOfStock = product.sizes.every(s => s.quantity <= 0);
  // const isOutOfStock = product?.sizes?.every((s) => s.quantity === 0);

console.log(product);


  return (
    <NavLink
      to={`/products/${title}/${product.code}`}
      key={product._id}
      className={calculateSalePrice(product) === "Invalid input" ? "item" : "item sale"}
    >
      <div className="image">
        {/* <img src={product.images} alt="Product" /> */}
        {product.images.length > 1 ?
          <Slider {...settings}>
            {product.images.map((image, idx) => (
              <div>
                <img key={idx} src={image} alt="product img" className="w-100" />
              </div>
            ))}
          </Slider>
          :
          <img src={product.images[0]} alt="product img" className="w-100" />
        }
      </div>
      <div className="info">
        <div className="name">{product.code}</div>
        <div className="text-center fw-bold">{product.description}</div>
        <div className="d-flex gap-4 w-100 justify-content-center size text-uppercase text-center mb-2 fs-5">
          {isOutOfStock ? (
            <span className="text-danger">Out of stock</span>
          ) : (
            product.sizes?.map(s => (
              <span key={s.size}>{s.quantity <= 0 ? null : s.size}</span>
            ))
          )}
        </div>
        {!isOutOfStock && (
          <div className="inner_info d-flex justify-content-between">
            {calculateSalePrice(product) === "Invalid input" ? (
              <div className="price">{product.price} EGP</div>
            ) : (
              <div className="price_dashed text-danger">{product.price} EGP</div>
            )}
            {calculateSalePrice(product) !== "Invalid input" ? (
              <span>{calculateSalePrice(product)} EGP</span>
            ) : null}
          </div>
        )}
      </div>
    </NavLink>
  );
}
