import React from "react";
import Product from "../components/Product";
import { Link } from "react-router-dom";
import { NavLink } from "react-bootstrap";


export default function Slider({
  title = String(),
  displayHeader = Boolean(),
  stock = [
    {
      _id: String(),
      sizes: [{ size: String(), quantity: Number() }],
      code: String(),
      model: String(),
      kit: String(),
      collectionName: String(),
      type: String(),
      price: Number(),
      description: String(),
      sale: Number(),
      newCollection: String(),
      league: String(),
      brand: String(),
      images: [],
      __v: Number(),
    },
  ],
}) {
  return (
    <div className={displayHeader ? "slider_container" : "slider d-flex"}>
      {displayHeader && (
        <>
          <h2>{title}</h2>
          <div className="all_products">
            <Link to={`/products/${title}/all`} className="all_products">
              See All
            </Link>
          </div>
        </>
      )}
      <div className="slider d-flex">
        {stock.map(product => (
          <Product title={title} key={product.code} product={product} />
        ))}
      </div>
    </div>
  );
}
