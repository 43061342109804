import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import img from '../images/Animation - 1709313300851.json'
import axios from 'axios';
import toast from 'react-hot-toast';
let backendUrl = process.env.REACT_APP_BACKEND;

export default function MyOrders() {
    const [orders, setOrders] = useState([]);

    const shippingCosts = {
        "القاهرة و الجيزة": 51,
        "الاسكندرية": 66,
        "الاسماعيلية": 70,
        "مدن الدلتا": 70,
        "صعيد مصر 1": 80,
        "صعيد مصر 2": 100,
        "المدن الجديدة - حلوان - شبرا الخيمة - المرج": 55,
        "اطراف الاسكندرية": 80,
        "مدن البحر الاحمر و الساحل و العين السخنه": 105,
        "اطراف الجيزة و القاهرة": 60,
        "اطراف الجمهورية": 155,
        "بورسعيد": 70,
        "السويس": 70,
    };

    let getMyOrders = () => {
        fetch(`${backendUrl}/api/myOrders`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(res => res.json())
            .then(data => {
                setOrders(data);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
            });
    };

    let deleteSingleOrder = orderId => {
        fetch(`${backendUrl}/api/deleteOneOrderForClient/${orderId}`, { method: "DELETE", credentials: 'include', })
            .then(res => res.json())
        window.location.reload();
    };

    useEffect(() => {
        getMyOrders();
    }, []);



    return (
        <div className="orders">
            <Container>
                {orders.length === 0 ? (
                    <div className='d-flex flex-column align-items-center'>
                        <div className="col-md-4 col-12">
                            <div className='text-center'>
                                <Lottie animationData={img}></Lottie>
                                <p className="text-white fs-3">You have no Orders !</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="cart_items text-white">
                        <h2 className='mb-5 text-center'>All Your Orders</h2>
                        <div className="row">
                            {orders.map((order, idx) => (
                                <div className="col-md-4" key={idx}>
                                    <div className='bg-light rounded-2 text-dark p-4  mb-3'>
                                        <h5>Order Date: {order.date.slice(8, 10)}-{order.date.slice(4, 7)}-{order.date.slice(11, 15)}</h5>
                                        <h5>Name: {order.name}</h5>
                                        <h5>Email: {order.email}</h5>
                                        <h5>Phone: {order.phone}</h5>
                                        <h5>Zone: {order.zone}</h5>
                                        <h5>Area: {order.area}</h5>
                                        <h5>Address: {order.address}</h5>
                                        <h5>Number Of Products: {order.productsOrdered.length}</h5>
                                        <h5>Total Price: {
                                            order.TotalPrice
                                            // order.productsOrdered.reduce((total, product) => total + (product.price * product.quantity), 0) + (shippingCosts[order.zone] || 0)
                                        } EGP</h5>
                                        {order.productsOrdered.map((product, pIdx) => (
                                            <div className='ms-2' key={pIdx}>
                                                <h6>Product {pIdx + 1}</h6>
                                                <div className="ms-5">
                                                    <h6>Code: {product.code}</h6>
                                                    <h6>Size: {product.size}</h6>
                                                    <h6>Price: {product.price}</h6>
                                                    <h6>Quantity: {product.quantity}</h6>
                                                </div>
                                            </div>
                                        ))}
                                        {/* <button className="mt-3 btn btn-danger d-block m-auto" onClick={() => deleteSingleOrder(order.orderId)}>Delete Order</button> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Container>
        </div>
    );
}
