import React, { useEffect, useRef, useState } from "react";
import * as formik from "formik";
import * as yup from "yup";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import image1 from "../images/carousel_3.jpeg";
import toast from "react-hot-toast";
import Slider from "react-slick";
import axios from "axios";
let backendUrl = process.env.REACT_APP_BACKEND;

const zonesData = {
  "القاهرة و الجيزة": [
    "ارض الجولف", "ارض اللواء", "مدينة نصر", "الاتوستوراد", "الامام الشافعي",
    "الاميرية", "البحر الاعظم جيزة", "البساتين", "التحرير", "الجمالية",
    "الحرفيين", "الحسانية", "الحلمية الجديدة", "الدراسة", "الدرب الاحمر",
    "الدقي", "كورنيش المعادي", "كورنيش النيل", "كيتكات", "ماسبيرو", "مترو",
    "مريوطية الهرم", "مريوطية فيصل", "مساكن الحلمية", "مصر الجديدة",
    "مصر القديمة", "منشية الصدر", "منشية ناصر", "ميدان الجيزة", "ميدان الجيش",
    "وسط البلد", "عبود", "عزبة النخل", "عمرانية", "عين شمس", "غمرة",
    "فيصل", "كوبري القبة", "جسر السويس", "حدائق الزيتون", "حدائق القبة",
    "حدائق المعادي", "حلمية الزيتون", "حمامات القبة", "دار السلام", "رمسيس",
    "روض الفرج", "زهراء المعادي", "سراي القبة", "زهراء مدينة نصر", "شبرا مصر",
    "شيراتون", "صلاح سالم", "عابدين", "الملك الصالح", "المنيل", "المهندسين",
    "الموسكي", "النزهة الجديدة", 'الهرم', 'الهضبة الوسطى', 'الوايلي', 'الوراق',
    'امبابة', 'باب الشعرية', 'باب اللوق', 'بولاق ابوالعلا', 'بولاق الدكرور', 'جاردن سيتي'
    , 'جامعه القاهرة', 'الدمرداش', 'الدويقة', 'الرماية', 'الزاوية الحمرا', 'الزمالك', 'الزيتون'
    , 'السبتية', 'السلام', 'السيدة زينب', 'السيدة عائشة', 'الشرابية', 'الضاهر', 'الطالبية',
    'الطريق الدائري', 'العباسية', 'العتبة', 'العجوزة', 'العمرانية', 'الفجالة', 'الفسطاط الجديدة',
    'القصر العيني', 'القلعه', 'الماظه', 'المريوطية', 'المريوطية', 'المطرية', 'المظلات', 'المعادي', 'المعادي الجديدة',
    'المقطم'
  ],
  "الاسكندرية": ['كليوباترا', 'كوبري الناموس', 'كوم الشقافه', 'لوران', 'محرم بيك',
    'محطة مصر', 'محمد محفوظ', 'محمد نجيب', 'مصطفى كامل', 'مطار الاسكندرية', 'مطار النزهة',
    'ميامي', 'ميامي الجديدة', ',وابور المياه', 'ونجت', 'العوايد', 'الفلكي', 'القادسية', 'اللبان',
    'المندرة', 'المنشية', 'الوزراة', 'باكوس', 'بوكلي', 'ثروت', 'جليم', 'جناكليس', 'خليل حمادة',
    'رشدي', 'زيزينيا', 'الابراهيمية', 'ابوقير', 'الازاريطة', 'الجامعه', 'الحضرة', 'الرأس السودا', 'الساعه',
    'السرايا', 'السلطان حسين', 'السيوف', 'الشاطبي', 'الشلالات', 'العصافرة', 'العطارين', 'سابا باشا',
    'سان ستيفانو', 'سبورتنج', 'ستانلي', 'سموحه', 'سيدي بشر', 'سيدي جابر', 'شدس', 'طوسون',
    'عزبة سعد', 'غبريال', 'فلمنج', 'فيكتوريا', 'كامبشيزار', 'كفر عبده', ''
  ],
  "الاسماعيلية": ['الاسماعيلية', 'التل', 'القنطرة', 'المستقبل', 'المنطقة الصناعيه', 'فايد'],
  "مدن الدلتا": ["راس البر", 'سمنود', 'شبين القناطر', 'طنطا', 'قليوب', 'كفر الدوار', 'كفر الزيات', 'كفر الشيخ',
    'مدينة السادات', 'منشأة القناطر', 'منيا القمح', 'ميت حلفا', 'ميت غمر', 'ميت نما', ',وردان المنوفية',
    'البحيرة', 'الدقهلية', 'الزقازيق', 'الشرقية', 'الغربية', 'القليوبية', 'القناطر', 'المحله', 'المنصورة', 'المنوفيه',
    'ايتاي البرود', 'بنها', 'دكمهور', 'دمياط', 'دمياط الجديدة'],
  "صعيد مصر 1": ['الفيوم', 'المنيا', 'بني سويف'],
  "صعيد مصر 2": ['اسوان', 'اسيوط', 'الاقصر', 'جرجا', 'سوهاج', 'قنا'],
  "المدن الجديدة - حلوان - شبرا الخيمة - المرج": ['15 مايو', '6 اكتوبر', 'new giza', 'التجمع الاول', 'التجمع التالت', 'التجمع الخامس', 'الرحاب',
    'الشروق', 'الشيخ زايد', 'العبور', 'القاهرة الجديدة', 'القرية الذكية', 'القطامية', 'المرج', 'المرج الجديدة',
    'بهتيم', 'حدائق اكتوبر', 'حدائق حلوان', 'حلوان', 'دريم لاند', 'شبرا الخيمة', 'طرة البلد', 'طريق الواحات 6 اكتوبر', 'طريق مصر اسكندرية الصحراوي',
    'مدينة المستقبل', 'مسطرد', 'هليوبليس الجديدة', 'وادي حوف'
  ],
  "اطراف الاسكندرية": ['النخيل', 'النوبارية', 'الهانوفيل', 'الورديان', 'الورديان اسكندرية', 'بحري', 'برج العرب', 'غيط العنب', 'كرموز', 'كوم الدكه',
    'كينج مريوط', 'موقف الاسكندرية', 'منيا البصل', 'وادي النطروون', 'ابو يوسف', 'ابيس', 'البيطاش', 'الدخيلة', 'الدخيلة اسكندرية', 'العامرية', 'العجمي', 'العوايد',
    'القباري', 'القباري اسكنريدة', 'الكيلو 21', 'المعمورة', 'المنتزه'
  ],
  "مدن البحر الاحمر و الساحل و العين السخنه": ['الساحل الشمالي', 'العين السخنه', 'الغردقة', 'راس سدر', 'سفاجا', 'مارينا', 'مرسى مطروح'],
  "اطراف الجيزة و القاهرة": ['ابو النمرس', 'ابو رواش', 'ابو زعبل', 'اطفيح', 'البدرشين', 'البراجيل', 'التبين', 'الجيل الاصفر', 'الحوامدية',
    'الخانكة', 'الخصوص', 'الصف', 'العاشر من رمضان', 'العاصمة الادارية', 'العياط', 'القلج', 'المعتميدة', 'المنصورية', 'المناشي', 'المنيب', 'اوسيم', 'باسوس', 'بشتيل',
    'دهشور', 'سقارة', 'شبرامنت', 'صفط اللبن', 'كرداسة', 'ميدنة بدر', 'منيل الشيحا', 'وراق الحضر', 'وراق العرب'
  ],
  "اطراف الجمهورية": ['الجونة', 'القصير', 'الواحات البحرية', 'الوادي الجديد', 'دهب', 'شرم الشيخ', 'طور سيناء', 'مرسى علم', 'نويبع'],
  "بورسعيد": ['بورسعيد', 'بورفؤاد'],
  "السويس": ["السويس"],

};

const shippingCosts = {
  "القاهرة و الجيزة": 51,
  "الاسكندرية": 66,
  "الاسماعيلية": 70,
  "مدن الدلتا": 70,
  "صعيد مصر 1": 80,
  "صعيد مصر 2": 100,
  "المدن الجديدة - حلوان - شبرا الخيمة - المرج": 55,
  "اطراف الاسكندرية": 80,
  "مدن البحر الاحمر و الساحل و العين السخنه": 105,
  "اطراف الجيزة و القاهرة": 60,
  "اطراف الجمهورية": 155,
  "بورسعيد": 70,
  "السويس": 70,
};

export default function Checkout({ setAppearFooter }) {


  const { Formik } = formik;
  const nav = useNavigate();
  const guestID = localStorage.getItem("guestID");

  const zoneRef = useRef(null);
  const areaRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const addressRef = useRef(null);
  const phoneRef = useRef(null);
  const termsRef = useRef(null);

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required().email(),
    address: yup.string().required(),
    phone: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]{11}$/, "Must be exactly 11 digits")
      .matches(/^01[0-9]{9}$/, "Phone number must start with 01")
      .min(11, "Must be 11 digits")
      .max(11, "Must be 11 digits"),
    note: yup.string(),
    zone: yup.string().required("Zone is required"),
    area: yup.string().required("Area is required"),
    termsCondition: yup.bool().required().oneOf([true], "Terms must be accepted"),
  });

  const [products, setProducts] = useState([]);
  const [areas, setAreas] = useState([]);
  const [shippingCost, setShippingCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);



  let getCartItems = () => {
    fetch(`${backendUrl}/api/cart`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setProducts(data)
      })
      .catch(error => {
        console.error('Error fetching cart items:', error);
      });
  };

  useEffect(() => {
    getCartItems();
  }, []);

  // const handlePurchase = async values => {
  //   const formData = new FormData();
  //   formData.append("name", values.name);
  //   formData.append("email", values.email);
  //   formData.append("address", values.address);
  //   formData.append("zone", values.zone);
  //   formData.append("area", values.area);
  //   formData.append("phone", values.phone);
  //   formData.append("note", values.note);
  //   formData.append("termsCondition", values.termsCondition);
  //   formData.append("totalPriceWithShipping", totalCost);

  //   let response = await fetch(`${backendUrl}/api/orders/add/${guestID}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       name: values.name,
  //       zone: values.zone,
  //       area: values.area,
  //       email: values.email,
  //       address: values.address,
  //       phone: values.phone,
  //       note: values.note,
  //       termsCondition: values.termsCondition,
  //       totalPriceWithShipping: totalCost,
  //     }),
  //   });

  //   response = await response.json();

  //   if (response === "saved") {
  //     let timerInterval;
  //     Swal.fire({
  //       title: "Order submitted. Please check your email address for order details.",
  //       timer: 7000,
  //       timerProgressBar: true,
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //       willClose: () => {
  //         clearInterval(timerInterval);
  //       },
  //     }).then(result => {
  //       if (result.dismiss === Swal.DismissReason.timer) {
  //         toast.success("Order Submitted");
  //         setTimeout(() => {
  //           nav('/myOrders');
  //           window.location.reload();
  //         }, 500);
  //       }
  //     });
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "An error occurred. Please try again...",
  //     });
  //   }
  // };


  const handlePurchase = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("address", values.address);
    formData.append("zone", values.zone);
    formData.append("area", values.area);
    formData.append("phone", values.phone);
    formData.append("note", values.note);
    formData.append("termsCondition", values.termsCondition);
    formData.append("totalPriceWithShipping", totalCost);

    try {
      const response = await axios.post(`${backendUrl}/api/orders/add/${guestID}`, {
        name: values.name,
        zone: values.zone,
        area: values.area,
        email: values.email,
        address: values.address,
        phone: values.phone,
        note: values.note,
        termsCondition: values.termsCondition,
        totalPriceWithShipping: totalCost,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response);
      
      // Check response status code and data
      if (response.status === 200 && response.data === "saved") {
        let timerInterval;
        Swal.fire({
          title: "Order submitted. Please check your email address for order details.",
          timer: 7000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            toast.success("Order Submitted");
            setTimeout(() => {
              nav('/myOrders');
              window.location.reload();
            }, 500);
          }
        });
      } else {
        // Handle non-successful responses here
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data?.message || "An error occurred. Please try again...",
        });
      }
    } catch (error) {
      // Handle network or server errors
      console.error("Error during API call:", error); // Log the error for debugging
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred. Please try again...",
      });
    }
  };

  useEffect(() => {
    getCartItems();
    setAppearFooter(false);

    return () => setAppearFooter(true);
  }, [setAppearFooter]);

  const handleZoneChange = (e, handleChange) => {
    const zone = e.target.value;
    handleChange(e);
    setAreas(zonesData[zone] || []);
    setShippingCost(shippingCosts[zone] || 0);
  };

  const calculateTotalCost = () => {
    const subtotal = products.reduce((total, product) => total + ((product.sale ? product.price - product.sale : product.price) * product.quantity), 0);
    setTotalCost(subtotal + shippingCost)
    return subtotal + shippingCost;
  };

  return (
    <Container className="checkout">
      <div className="d-flex align-items-center flex-column w-100 gap-3">
        <Formik
          validationSchema={schema}
          initialValues={{
            name: "",
            zone: "",
            area: "",
            email: "",
            address: "",
            phone: "",
            note: "",
            termsCondition: false,
          }}
          onSubmit={values => {
            handlePurchase(values);
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form className="mt-5" noValidate onSubmit={handleSubmit}>
              <Row className="contact mb-5">
                <div className="headlines d-flex justify-content-between align-items-center">
                  <h2>Contact</h2>
                  {/* <p className="m-0">
                    Have an account? <NavLink to="/">Log in</NavLink>
                  </p> */}
                </div>
                <Form.Group as={Col} controlId="validationFormik101" className="position-relative" ref={nameRef}>
                  <Form.Control type="text" name="name" placeholder="Name" value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <h2>Delivery</h2>
                <div className="mb-4">
                  <formik.Field
                    as="select"
                    name="zone"
                    className="w-100 rounded"
                    onChange={e => handleZoneChange(e, handleChange)}
                    isInvalid={touched.zone && !!errors.zone}
                    innerRef={zoneRef}
                  >
                    <option value="">Select Zone</option>
                    {Object.keys(zonesData).map(zone => (
                      <option key={zone} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </formik.Field>
                  {touched.zone && errors.zone && (
                    <span className="text-danger bg-danger text-white p-1 fs-6 rounded mt-2 d-inline-block">
                      please select a zone
                    </span>
                  )}
                </div>
              </Row>
              <Row>
                <div className="mb-4">
                  <formik.Field as="select" name="area" className="w-100 rounded" innerRef={areaRef}>
                    <option value="">Select Area</option>
                    {areas.map(area => (
                      <option key={area} value={area}>
                        {area}
                      </option>
                    ))}
                  </formik.Field>
                  {touched.area && errors.area && (
                    <span className="text-danger bg-danger text-white p-1 fs-6 rounded mt-2 d-inline-block">
                      please select an area
                    </span>
                  )}
                </div>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="validationFormik101" className="position-relative mb-4">
                  <Form.Control type="text" name="email" placeholder="Email address" value={values.email} onChange={handleChange} isInvalid={!!errors.email} ref={emailRef} />
                  <Form.Control.Feedback type="invalid" tooltip>
                    <span className="text-danger bg-danger text-white p-1 fs-6 rounded d-inline-block">{errors.email}</span>
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="validationFormik101" className="position-relative mb-4">
                  <Form.Control type="text" name="phone" placeholder="Phone Number" value={values.phone} onChange={handleChange} isInvalid={!!errors.phone} ref={phoneRef} />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="validationFormik101" className="position-relative mb-4">
                  <Form.Control type="text" name="address" placeholder="Address" value={values.address} onChange={handleChange} isInvalid={!!errors.address} ref={addressRef} />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="validationFormik101" className="position-relative mb-4">
                  <Form.Control
                    as="textarea"
                    rows={5}
                    // type="textarea"
                    name="note"
                    placeholder="Note (optional)"
                    value={values.note}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <div className="d-flex justify-content-between">
                <Row>
                  <Form.Group className="position-relative mb-5">
                    <Form.Check
                      required
                      className="termsCondition"
                      name="termsCondition"
                      label="Agree to terms and conditions"
                      onChange={handleChange}
                      isInvalid={!!errors.termsCondition}
                      feedback="You must agree to terms and conditions"
                      feedbackType="invalid"
                      id="validationFormik106"
                      feedbackTooltip
                      ref={termsRef}
                    />
                  </Form.Group>
                </Row>
                <div className="text-end">
                  <NavLink className="text-white text-decoration-underline d-block" to="/refund-exchange-policy">
                    Refund & Exchange Policy
                  </NavLink>
                </div>
              </div>

              <h2 className="mb-3 mt-3 summary">Order Summary</h2>
              <div className="floating_receipt pb-4 w-100">
                <div className="content w-100">
                  <div className="item w-100">
                    <SliderInCheckout stock={products} />
                    <div className="totals w-100">
                      <div className="d-flex w-100 justify-content-start">
                        <span style={{ width: "8rem" }}>Subtotal: </span>
                        <p>
                          {products.reduce((total, product) => total + ((product.sale ? product.price - product.sale : product.price) * product.quantity), 0)} EGP
                        </p>
                      </div>
                      <div className="d-flex w-100 justify-content-start">
                        <span style={{ width: "8rem" }}>Shipping: </span>
                        <p>{shippingCost ? shippingCost : "Select zone first"}</p>
                      </div>
                      <div className="d-flex w-100 justify-content-start">
                        <span style={{ width: "8rem", fontSize: "1.5rem" }}>Total: </span>
                        <p style={{ fontSize: "1.5rem" }}>
                          {calculateTotalCost()} EGP
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="b">
                <Button className="w-100 pt-2 pb-2 fs-5 mt-3" type="submit"
                  onClick={() => {
                    // Scroll to the first empty field
                    if (errors.name && touched.name) nameRef.current.scrollIntoView();
                    else if (errors.email && touched.email) emailRef.current.scrollIntoView();
                    else if (errors.zone && touched.zone) zoneRef.current.scrollIntoView();
                    else if (errors.area && touched.area) areaRef.current.scrollIntoView();
                    else if (errors.address && touched.address) addressRef.current.scrollIntoView();
                    else if (errors.phone && touched.phone) phoneRef.current.scrollIntoView();
                    else if (errors.termsCondition && touched.termsCondition) termsRef.current.scrollIntoView();
                  }}
                >
                  Order Now
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}

const SliderInCheckout = ({ stock }) => {
  return (
    <div className="slider d-flex w-100" style={{ border: "none" }}>
      <div className="slider d-flex">
        {stock.map(product => (
          <ProductInCheckout key={product.code} product={product} />
        ))}
      </div>
    </div>
  );
};

const ProductInCheckout = ({ product }) => {
  const calculateSalePrice = product => {
    if (typeof product.price !== "number" || typeof product.sale !== "number") {
      return "Invalid input";
    }

    // const salePrice = product.price - (product.price * product.sale) / 100;
    const salePrice = product.price - product.sale;
    return salePrice.toFixed(2);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className={calculateSalePrice(product) === "Invalid input" ? "item" : "item sale"}>
      <div className="image">
        {/* <img src={Boolean(product.images) ? product.images : image1} alt="Product" /> */}
        {product.images.length > 1 ?
          <Slider {...settings}>
            {product.images.map((image, idx) => (
              <div>
                <img key={idx} src={image} alt="product img" className="w-100" />
              </div>
            ))}
          </Slider>
          :
          <img src={product.images[0]} alt="product img" className="w-100" />
        }
      </div>
      <div className="info">
        <div className="name">{product.code}</div>
        <div className="text-center fw-bold">{product.description}</div>
        <div className="w-100 size text-uppercase text-center mb-2 fs-5">{product.size.toUpperCase()}</div>
        <div className="inner_info d-flex justify-content-between">
          {calculateSalePrice(product) === "Invalid input" ? (
            <div className="price">{product.price}EGP</div>
          ) : (
            <div className="price_dashed text-danger">{product.price}EGP</div>
          )}
          {calculateSalePrice(product) !== "Invalid input" ? <span>{calculateSalePrice(product)}EGP</span> : null}
        </div>
      </div>

      {/* <div className="w-100 text-center mt-4">{product.quantity}</div> */}
    </div>
  );
};
